html,
body {
    font-family: 'Poppins', sans-serif !important;
    color: white;
}

.info-card:hover {
    box-shadow: rgb(44, 244, 139) 0px 0px 2rem;
}


@media screen and (min-width: 1600px) {

    #hero,
    #impact,
    #defi,
    #nondilutive,
    #audits,
    #partnerships,
    #why,
    #roadmap,
    #team,
    #faq-container,
    #footer-desktop {
        /* margin-left: 10%;
        margin-right: 10%; */
        width: 70%;
        margin: 0 auto;

    }

    .background-uncorrelated {
        background-position: 35% 10%;
    }

    #nav-bar {
        padding-left: 15%;
        padding-right: 15%
    }
}


/* Dashboard Footer */

/* .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
} */

footer main {
    overflow: visible !important;
}

.nav-button:hover {
    box-shadow: 0px 0px 0px 2px #2CF48B;
}

a.menu-item:not(.active):hover {
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    font: inherit;
    text-decoration: none;
    margin: 0;
    background: transparent;
    overflow: visible;
    text-transform: none;
    border-radius: 4px;
    color: #000;
    padding: 4px 12px;
    font-size: 18px;
    line-height: 24px;
    background-color: #2CF48B;
    border-radius: 4px;
    transition-property: color, background-color, border-color, box-shadow;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.bottom-background {
    background: url('../Assets/footer_bg@2x.png'), url('../Assets/dots_r-l_diagonal@2x.png');
    background-position: 0 0, 10% 35%;
    background-size: 100vw, 130vw;
    background-repeat: no-repeat;

}

.bottom-background-small {
    background: url('../Assets/footer_bg@2x.png'), url('../Assets/dots_r-l_diagonal@2x.png');
    background-position: 50% 100px, 0 -50px;
    background-size: 150vw, 160vw;
    background-repeat: no-repeat;
}

.inner-container {
    width: 100%;
}

.footer-logo {
    max-width: 170px !important;
    margin-bottom: 28px !important;
}

.footer-logo-mobile {
    margin-bottom: 24px !important;
    max-width: 170px !important;
}

.footer-email-input-desktop {
    transition-property: width !important;
    transition-duration: 0.3s !important;
    transition-timing-function: linear !important;
    width: 280px !important;
    /* color: white ; */
}

.footer-email-input-desktop:focus {
    width: 400px !important;
}


.foot-icon:hover {
    transform: scale(1.15);
}

.foot-icon-container {
    overflow: visible !important;
    margin: 0 auto;
}

.foot-icon {
    padding: 4px;
    margin: 6px;
}

.foot-icon:hover {
    cursor: pointer;
}

.foot-icon {
    transition: .2s;
}

.footer-link-text {
    font-size: 14px;
    color: #2CF48B;
    margin: 0 5px;
}

.foot-icon-mobile-nav>* {
    fill: #2CF48B;
}

.foot-icon-mobile-nav {
    padding: 10px;
}





/* Dashboard Nav */

.header-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #000;
    width: 100%;
}

.app-bar {
    width: 100% !important;
    /* max-width: 65em !important; */
}

.nav-logo {
    max-width: 170px !important;
}

.nav-logo-mobile {
    max-width: 130px !important;
    padding: 10px 15px;
}

.menu-item,
.menu-item-mobile {
    padding: 10px;
    color: #fff;
    text-decoration: none;
    margin: 0;
    background: transparent;
    overflow: visible;

}

.menu-item-mobile {
    font-size: 1.75rem !important;
}

.menu-item.active {
    border-top: 2px solid #2CF48B;
}

.menu-item-mobile.active {
    border-left: 2px solid #2CF48B;
}


.launchpad-button {
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    font: inherit;
    -webkit-text-decoration: none;
    text-decoration: none;
    /* margin-left: 25px; */
    background: transparent;
    overflow: visible;
    text-transform: none;
    text-decoration: none;
    border: 2px solid #2CF48B;
    border-radius: 4px;
    color: #f8f8f8;
    padding: 4px 10px;
    min-width: 145px;
    font-size: 18px;
    line-height: 24px;
    background-color: #2CF48B;
    color: #444444;
    min-height: 40px !important;
    border-radius: 4px;
    -webkit-transition-property: color, background-color, border-color, box-shadow;
    transition-property: color, background-color, border-color, box-shadow;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
}

.launchpad-button:hover {
    box-shadow: 0px 0px 0px 2px #2CF48B;
}

.launchpad-button-small {
    margin: 0 auto;
    font-weight: bold;
    padding: 4px 10px;
    text-decoration: none;
}

.launchpad-button-audited {
    margin: 0 auto;
    margin-top: 10px;
    width: 120px;
    padding: 4px 10px;
}

.nav-button-img {

    vertical-align: middle;
    max-height: 24px !important;
    margin-left: 2px;
}








/* NAV MENU */


button:focus {
    box-shadow: none !important;
}

img {
    align-self: center;
}

.exchange-avatar {
    align-self: center;
    margin-right: 12px;
    margin-left: 30px;
}

.video-header {
    position: absolute;
    top: 0px;
    z-index: 2;
    overflow: hidden !important;
    padding-bottom: 50px !important;
}

.video-container {
    position: relative;
}

@media only screen and (min-width: 600px) {
    .video-container {
        min-height: 30em !important;
        max-height: 40% !important;
    }
}

@media only screen and (max-width: 599px) {
    .video-container {
        min-height: 25em !important;
    }
}


.world-map {
    max-width: 100%;
    width: auto !important;
    height: auto !important;
    background-color: rgba(0, 0, 0, 1) !important;
    padding: 25px;
    border-radius: 24px;
}



body {
    min-height: 100% !important;
}



.bio-icon:hover {
    cursor: pointer;
}

.bio-icon {
    text-align: center !important;
    align-self: center !important;
    color: #2CF48B !important;
}

.bio-container:hover,
    {
    /* box-shadow: 0 0px 40px 0 rgba(255, 255, 255, .7) */
}

.buy-box:hover {
    box-shadow: 0 0px 40px 0 rgba(255, 255, 255, .7)
}





@media only screen and (max-width: 400px) {
    .team-grid {
        display: block !important;
    }
}

.divider-with-margin {
    width: 100%;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 0px;
    border-top: 2px solid #2CF48B;
}





.center {
    text-align: center !important;
}

.faq-container a {
    color: #2CF48B !important;
}

* {
    outline: none;
}